import { render, staticRenderFns } from "./mallList.vue?vue&type=template&id=328cd484&scoped=true&"
import script from "./mallList.js?vue&type=script&lang=js&"
export * from "./mallList.js?vue&type=script&lang=js&"
import style0 from "./mallList.css?vue&type=style&index=0&id=328cd484&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "328cd484",
  null
  
)

export default component.exports