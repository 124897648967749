export default {
    name: 'mallList',
    data() {
        return {
            list: [],
            mall_id: ''
        }
    },
    created() {
        document.title = "商场列表";
        if (localStorage.getItem('mall_id')) {
            this.$router.replace({
                name: 'login'
            });
            return
        }
        //获取商场列表
        this.$UtilsApi.mallList({}).then(res => {
            if (res.data.code == 0) {
                this.mall_id = res.data.data[0].id;
                this.list = res.data.data;
                localStorage.setItem('mall_list', JSON.stringify(this.list));
            } else {
                this.$notify({ type: 'warning', message: res.data.msg, duration: 2000 });
            }
        });
    },
    methods: {
        selectMall(id) {
            this.mall_id = id;
        },
        toHome() {
            localStorage.setItem('mall_id', this.mall_id);
            this.$router.replace({
                name: 'home'
            })
        }
    },
}